.Novels {
  background: #1a1a1a;
  color: #b7b7b7;
  position: relative;
  z-index: 100;
  padding: 1rem;
  min-height: 100vh;
  box-sizing: border-box;
  a {
    color: inherit;
  }
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}
