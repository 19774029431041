.BenzeneSpace {
  transform-style: preserve-3d;
  perspective: 2000px;
  @keyframes Benzene {
    from {
      transform: translate3d(var(--x), var(--y), -30000px) translate(-50%, -50%)
        rotateZ(0deg);
      opacity: 0;
    }
    to {
      transform: translate3d(var(--x), var(--y), 2000px) translate(-50%, -50%)
        rotateZ(360deg);
      opacity: 0.7;
    }
  }
  img {
    position: absolute;
    width: 160px;
    height: 160px;
    top: 0;
    left: 0;
    animation: Benzene forwards linear;
    opacity: 0.7;
    animation-duration: var(--duration);
  }
}
