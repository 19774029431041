.Landing {
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: relative;
  z-index: 100;

  .buttons {
    > * {
      margin: 2em;
    }
  }

  h1 {
    margin-bottom: 0;
  }
  h4 {
    margin-top: 0;
  }
}
