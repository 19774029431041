.NovelsList {
  .Header {
    margin: -1rem -1rem 0 -1rem;
    img {
      margin-right: 1ch;
      display: block;
    }
  }
  .Footer {
    margin: 0 -1rem -1rem -1rem;
    svg {
      display: block;
    }
  }

  .title {
    position: relative;
  }

  padding-top: var(--height);
  line-height: 40px;

  color: #d6d6d6;
  .size {
    color: #858585;
    font-size: 0.8em;
  }
}
