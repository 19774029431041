.RecentReading {
  position: absolute;
  color: #d29;
  font-size: 0.8em;
  bottom: 100%;
  left: 100%;
  white-space: nowrap;
  line-height: 1.5;
  padding-top: 2em;
  pointer-events: none;
  svg {
    height: 10px;
    // outline: 1px solid green;
    display: inline-block;
    vertical-align: bottom;
    fill: none;
    stroke: currentColor;
  }
}
