.Loading {
  height: 48px;
  padding: 0 1rem;
  margin: -1rem;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  .Logo {
    filter: grayscale(1);
    animation: loading-rotate linear 3s infinite;
    margin-right: 1ch;
  }
  animation: loading-ddd linear 0.5s infinite;
  &::after {
    content: var(--content);
  }
}

@keyframes loading-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes loading-ddd {
  0% {
    --content: " ";
  }
  25% {
    --content: " ·";
  }
  50% {
    --content: " ··";
  }
  75% {
    --content: " ···";
  }
}
