.NovelsArticle {
  padding-top: 48px;

  article {
    p {
      &:first-child {
        margin-top: 0;
      }
      line-height: 1.9;
      margin: 0.8em 0;
    }
  }

  .Header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 1rem;
    width: 100%;
    background: #1f1f1f;
    color: #d6d6d6;
    .Logo {
      margin-right: 1ch;
      display: block;
    }
  }
  .Footer {
    margin: 1em -1em -1em -1em;
  }
}
