.Belt {
  box-sizing: border-box;
  display: flex;
  padding: 0 1rem;
  background: #1f1f1f;
  height: 48px;
  align-items: center;
  color: #d6d6d6;
  font-size: 0.9rem;
}
